import type { IndexedMovie, MoviePreview } from '~/types/movie'

export function mapIndexedMovieToPreview(movie: IndexedMovie): MoviePreview {
  return {
    title: movie.title,
    poster: movie.poster,
    picture: movie.picture,
    visible: movie.visible,
    year: movie.year!,
    id: movie.id,
    mfi_url: movie.mfi_url,
    cote: movie.cote,
    date_updated: movie.date_updated,
    summary: movie.summary_synopsis
  }
}
export function interval(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
