import type { MfiAPI } from '@hubelia/mfiapi'
import {
  ArticlesIndexes,
  ContactsIndexes,
  ListsIndexes,
  MoviesIndexes,
  TVSchedulesIndexes
} from '~/consts/meilisearch'
import type { AdvancedSearchQueries, IndexedMovie, MoviePreview } from '~/types/movie'
import { mapIndexedMovieToPreview } from '~/helpers/meilisearch'
import type { IndexedArticle } from '~/types/article'
import type { IndexedContact } from '~/types/contacts'
import type { IndexedList } from '~/types/lists'
import { type IndexedTVSchedule } from '~/types/tv-schedules'
import { excludedChannels } from '~/consts/general'

export async function searchMovies<T = MoviePreview>(params: {
  q?: string
  locale: string
  matchingStrategy?: string
  attributesToSearchOn?: Array<string>
  filter?: string | Array<string> | Array<Array<string>>
  sort?: string
  fields?: Array<keyof IndexedMovie>
  sdk: MfiAPI
  pagination?: {
    hitsPerPage: number
    page: number
  }
  offset?: number
  limit?: number
  transform?: (movie: IndexedMovie) => T
}) {
  const {
    locale,
    sdk,
    sort,
    filter,
    q,
    attributesToSearchOn,
    fields = [
      'title',
      'mfi_url',
      'poster',
      'picture',
      'year',
      'id',
      'cote',
      'summary_synopsis',
      'realisator',
      'visible'
    ],
    offset,
    matchingStrategy,
    limit,
    transform
  } = params

  const index = MoviesIndexes[locale as 'fr' | 'en']

  if (!index) {
    throw new Error('Unable to get a movies index for the given locale')
  }

  return await sdk.v1.mfi.search
    .run(index, {
      attributesToRetrieve: fields,
      filter,
      matchingStrategy,
      ...(q && { q }),
      ...(attributesToSearchOn && { attributesToSearchOn }),
      ...(sort && { sort: [sort] }),
      ...(params.pagination && {
        hitsPerPage: params.pagination.hitsPerPage,
        page: params.pagination.page
      }),
      ...(offset && { offset }),
      ...(limit && { limit })
    })
    .then((res) => {
      const meiliMovies: Array<IndexedMovie> = res?.data?.hits ?? []

      return {
        data: meiliMovies.map((movie) =>
          transform ? transform(movie) : mapIndexedMovieToPreview(movie)
        ) as Array<MoviePreview>,
        totalPages: res?.data?.totalPages,
        page: res?.data?.page,
        totalHits: res?.data?.totalHits,
        estimatedTotalHits: res?.data?.estimatedTotalHits
      }
    })
}

export async function searchAdvancedMovies(params: {
  queries?: MaybeRefOrGetter<AdvancedSearchQueries>
  locale: string
  matchingStrategy?: string
  attributesToSearchOn?: Array<string>
  filter?: string | Array<string> | Array<Array<string>>
  sort?: string
  fields?: Array<keyof IndexedMovie>
  sdk: MfiAPI
  pagination?: {
    hitsPerPage: number
    page: number
  }
  offset?: number
  limit?: number
}) {
  const {
    locale,
    sdk,
    sort,
    filter,
    queries,
    attributesToSearchOn,
    fields = ['title', 'mfi_url', 'poster', 'picture', 'year', 'id', 'cote', 'visible'],
    offset,
    matchingStrategy,
    limit
  } = params

  const index = MoviesIndexes[locale as 'fr' | 'en']

  if (!index) {
    throw new Error('Unable to get a movies index for the given locale')
  }

  return await sdk.v1.mfi.search
    .advancedSearch(
      {},
      {
        index,
        attributesToRetrieve: fields,
        filter,
        searchQueries: queries,
        matchingStrategy,
        ...(attributesToSearchOn && { attributesToSearchOn }),
        ...(sort && { sort: [sort] }),
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(params.pagination && {
          page: params.pagination
        })
      }
    )
    .then((res) => {
      const meiliMovies: Array<IndexedMovie> = res?.data?.hits ?? []

      return {
        data: meiliMovies.map((movie) => mapIndexedMovieToPreview(movie)) as Array<MoviePreview>,
        pagesInfo: res?.data?.pagesInfo
      }
    })
}
export async function searchAll(params: {
  query: string
  locale: string
  limit?: number
  sdk: MfiAPI
}) {
  const { locale, sdk, query, limit = 1000 } = params

  const indexes = {
    movies: MoviesIndexes[locale as 'fr' | 'en'],
    contacts: ContactsIndexes[locale as 'fr' | 'en'],
    articles: ArticlesIndexes[locale as 'fr' | 'en'],
    lists: ListsIndexes[locale as 'fr' | 'en']
  }

  for (const index in indexes) {
    if (!indexes[index as keyof typeof indexes])
      throw new Error(`Unable to get a ${index} index for the given locale`)
  }

  return await sdk.v1.mfi.search
    .multiRun(
      {},
      {
        q: query,
        locale,
        limit
      }
    )
    .then((res) => {
      const movies: Array<MoviePreview> = []
      const contacts: Array<IndexedContact> = []
      const articles: Array<IndexedArticle> = []
      const lists: Array<IndexedList> = []

      if (res.data?.results) {
        for (const entry of res.data.results) {
          switch (entry.indexUid) {
            case indexes.movies:
              movies.push(...(entry.hits as Array<IndexedMovie>).map(mapIndexedMovieToPreview))
              break
            case indexes.articles:
              articles.push(...(entry.hits as Array<IndexedArticle>))
              break
            case indexes.contacts:
              contacts.push(...(entry.hits as Array<IndexedContact>))
              break
            case indexes.lists:
              lists.push(...(entry.hits as Array<IndexedList>))
              break
          }
        }

        return {
          movies,
          articles,
          contacts,
          lists
        }
      } else {
        throw new Error('Got unexpected response from the multi search endpoint.')
      }
    })
}

export async function searchArticles(params: {
  locale: string
  filter?: string | Array<string> | Array<Array<string>>
  sort?: string
  sdk: MfiAPI
  fields?: Array<string>
  pagination?: {
    hitsPerPage: number
    page: number
  }
  offset?: number
  limit?: number
}) {
  const { locale, sdk, sort, filter, limit, offset } = params

  const index = ArticlesIndexes[locale as 'fr' | 'en']

  if (!index) {
    throw new Error('Unable to get an article index for the given locale')
  }

  return await sdk.v1.mfi.search
    .run(index, {
      filter,
      ...(sort && { sort: [sort] }),
      ...(params.pagination && {
        hitsPerPage: params.pagination.hitsPerPage,
        page: params.pagination.page
      }),
      ...(offset && { offset }),
      ...(limit && { limit })
    })
    .then((res) => {
      const meiliArticles: Array<IndexedArticle> = res.data.hits ?? []

      return {
        data: meiliArticles,
        totalPages: res.data.totalPages,
        page: res.data.page,
        totalHits: res.data.totalHits,
        estimatedTotalHits: res.data.estimatedTotalHits
      }
    })
}

export async function searchContacts(params: {
  locale: string
  filter?: string | Array<string> | Array<Array<string>>
  sort?: string
  sdk: MfiAPI
  fields?: Array<string>
  pagination?: {
    hitsPerPage: number
    page: number
  }
  offset?: number
  limit?: number
}) {
  const { locale, sdk, sort, filter, limit, offset, fields } = params

  const index = ContactsIndexes[locale as 'fr' | 'en']

  if (!index) {
    throw new Error('Unable to get an contact index for the given locale')
  }

  return await sdk.v1.mfi.search
    .run(index, {
      attributesToRetrieve: fields ?? ['id', 'full_name', 'image'],
      filter,
      ...(sort && { sort: [sort] }),
      ...(params.pagination && {
        hitsPerPage: params.pagination.hitsPerPage,
        page: params.pagination.page
      }),
      ...(offset && { offset }),
      ...(limit && { limit })
    })
    .then((res) => {
      const meiliContacts: Array<IndexedContact> = res.data.hits ?? []

      return {
        data: meiliContacts,
        totalPages: res.data.totalPages,
        page: res.data.page,
        totalHits: res.data.totalHits,
        estimatedTotalHits: res.data.estimatedTotalHits
      }
    })
}

export async function searchTVSchedules(params: {
  locale: string
  filter?: string | Array<string> | Array<Array<string>>
  sort?: string | Array<string>
  sdk: MfiAPI
  pagination?: {
    hitsPerPage: number
    page: number
  }
}) {
  const { locale, sdk, sort, filter } = params

  const index = TVSchedulesIndexes[locale as 'fr' | 'en']

  if (!index) {
    throw new Error('Unable to get a TV schedule index for the given locale')
  }

  return await sdk.v1.mfi.search
    .run(index, {
      filter,
      ...(sort && { sort: Array.isArray(sort) ? sort : [sort] }),
      ...(params.pagination && {
        hitsPerPage: params.pagination.hitsPerPage,
        page: params.pagination.page
      })
    })
    .then((res) => {
      const tvSchedules: Array<IndexedTVSchedule> = res.data.hits ?? []

      return {
        data: tvSchedules,
        totalPages: res.data.totalPages,
        page: res.data.page,
        totalHits: res.data.totalHits
      }
    })
}

export async function searchChannels(params: { sdk: MfiAPI }) {
  const { sdk } = params

  return await sdk.v1.mfi.search
    .run('channels', {
      limit: 99999
    })
    .then((res) => {
      return res.data.hits.filter((el) => !excludedChannels.includes(el.name)) as Array<{
        name: string
        id: number
      }>
    })
}
